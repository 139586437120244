pre.highlight > button {
  opacity: 0;
}

pre.highlight:hover > button {
  opacity: 1;
}

pre.highlight > button:active,
pre.highlight > button:focus {
  opacity: 1;
}

.code-block, pre.highlight.code-block {
    position: relative;
}

.copy-button {
    position: absolute;
    top: 0.2rem;
    right: 0.2rem;
    border-color: $dark-text-base-color;
    color: $dark-text-base-color;
    background-color: $dark-black;

    &:hover {
      background-color: $dark-smoke;
    }
}