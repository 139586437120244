@import "variables";

:root {
  --text-base-color: #{$text-base-color};
  --text-link-blue: #{$text-link-blue};
  --text-link-blue-active: #{$text-link-blue-active};
  --black: #{$black};
  --light: #{$light};
  --smoke: #{$smoke};
  --gray: #{$gray};
  --white: #{$white};
  --background-color: #{$white};
  --success: #{$success};
  --success-text: #{$black};

  body[data-theme="dark"] {
    --text-base-color: #{$dark-text-base-color};
    --text-link-blue: #{$dark-text-link-blue};
    --text-link-blue-active: #{$dark-text-link-blue-active};
    --black: #{$dark-black};
    --light: #{$dark-light};
    --smoke: #{$dark-smoke};
    --gray: #{$dark-gray};
    --white: #{$dark-white};
    --background-color: #{$dark-black};
    --success: #{$dark-success};
    --success-text: #{$black};
  }
}

@import "klise/fonts", "klise/base", "klise/layout", "klise/post",
  "klise/miscellaneous", "klise/syntax", "klise/dark";
@import "project", "alert", "_copy_button", "misc", "utils";
