.wide, .wide-image {
    width: calc(100vw - 20px);
    margin-left: calc(-50vw + 50% + 10px);
}

.wide-image {
    max-height: 50vh;
    overflow: hidden;
}

aside {
    background-color: var(--smoke);
    border-radius: 10px;
    padding: 1em;
    margin-left: 4em;
    margin-right: 2em;
    border: 1px solid var(--gray);

    @include media-query($on-mobile) {
        margin: 0;
    }
}

.link-summary-tooltip {
  position: relative;
  display: inline;
  border-bottom: 1px dotted;
}

.link-summary-tooltip-content {
  visibility: hidden;
  width: 500px;
  background-color: var(--background-color);
  border: 1px solid var(--smoke);
  color: var(--text-base-color);
  text-align: center;
  padding: 5px;
  border-radius: 1px;
  display: flex;
  position: absolute;
  z-index: 1;
  flex-direction: row;
  margin: 5px;
  max-width: 80vw;
}

.link-summary-image {
    width: 50px;
    height: 50px;
    margin: 0;
}

.link-summary-title-container {
    display: flex;
    flex-direction: column;
}

.link-summary-description {
    font-size: .7rem;
    text-align: left;
    margin-left: 1rem;
}

.link-summary-tooltip:hover + .link-summary-tooltip-content {
  visibility: visible;
}

.toc-container {
    border: 1px solid;
}

.toc-top-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.toc-title {
    margin-left: 1rem;
}

.toc-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
}

.toc-expander {
    border: none;
    margin: .3em .3em;
    cursor: pointer;
    &::after {
        content: '\02795';
    }
    .active & {
        &::after {
            content: '\2796';
        }
    }
}

.picture-container {
  position: relative;

  &.gif-container {
    cursor: pointer;
    width: fit-content;
  }

  .play-button {
    position: absolute;
    width: 80px;
    height: 80px;
    top: calc(50% - 40px);
    left: calc(50% - 40px);
    z-index: 1;
    opacity: .7;
    border-radius: 139px;
    background: gray;
    transition: all .5s; 
    margin: 0;
    padding: 0;
    border: none;
    cursor: pointer;

    img {
        filter: invert(99%) sepia(2%) saturate(662%) hue-rotate(268deg) brightness(111%) contrast(73%);
    }
  }

  &:hover .play-button,
  &:active .play-button,
  &:focus .play-button {
    box-shadow: 0px 0px 10px 5px var(--text-link-blue-active);
    width: 100px;
    height: 100px;
    top: calc(50% - 50px);
    left: calc(50% - 50px);
    transition: all .5s;
  }
}

.reaction-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.like-button {
    position: relative;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    background: none;
    font-size: 1.5rem;
    cursor: pointer;
    border: none;
    padding: 0;
}

.like-icon {
    border: 1px solid var(--smoke);
    border-radius: 100px;
    padding: 1rem 1.75rem;
    margin-bottom: 4px;
}

.like-button, .like-icon {
    transition: all 0.5s;
}

.like-button:disabled {
    cursor: default;
    &:hover {
        background: none;
    }
}

.liked {
    .thanks {
        display: block;
    }
    .like-icon {
        background-image: linear-gradient(to right top, #051937, #004d7a, #008793, #00bf72, #a8eb12);
    }
}


.thanks {
    position: absolute;
    display: none;
    font-size: 1rem;
    color: var(--background-color);
    transition: all 1s;
    transform: rotate(-45deg);
    top: 3%;
    left: -20%;
    background-color: var(--text-link-blue);
    border-radius: 5px;
    padding: 5px 13px;
    font-weight: bold;
}

.confetti {
    position: fixed;
    width: 100vw;
    height: 100vh;
}

.divider {
    width: 100%;
    margin: 2rem 0;
}